import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'

const BlockEffect = ({ lineHeight, content, y, delay, duration, to }) => {
  const BlockContainer = styled.div`
    overflow: hidden;
  `

  const Block = styled.div`
    line-height: ${lineHeight || 1.2};
    transform: translateY(${to || null}px);
  `

  let refContainer = useRef(null)

  const [refView, inView] = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView && !to) {
      gsap.from(refContainer, {
        opacity: 0,
        y: y,
        duration: duration || 0.8,
        delay: delay || 0,
      })
    } else if (inView && to) {
      gsap.to(refContainer, {
        opacity: 1,
        y: y || 0,
        duration: duration || 0.8,
        delay: delay || 0,
      })
    }
  }, [inView])

  return (
    <>
      <div ref={refView}>
        <BlockContainer>
          <Block ref={div => (refContainer = div)} className={`block`}>
            {content}
          </Block>
        </BlockContainer>
      </div>
    </>
  )
}

export default BlockEffect
