import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import Services from '../components/Services'
import Clients from '../components/Clients'
import ContactUs from '../components/ContactUs'
import PastWorkList from '../components/posts/PostList'
import ScrollingText from '../components/ScrollingText'
import Hero from '../components/Hero'
import { scroller } from 'react-scroll'
import gsap from 'gsap'

const Posts = ({ data, pageContext, location, previousLocation }) => {
  const hero = data.allContentfulHero.nodes[0]
  const services = data.allContentfulService.edges
  const clients = data.allContentfulClients.edges

  // this useEffect is to scroll to the correct position when the user clicks on a post
  useEffect(() => {
    if (location.state !== null) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 0,
        delay: 0,
        smooth: true,
      })
    }
  }, [])

  let loaderRef = useRef(null)
  let loaderText = useRef(null)

  // this hideLoader function is to hide the loader when the page is loaded
  const hideLoader = () => {
    const tl = gsap.timeline({
      onComplete: () => {
        loaderRef.style.display = 'none'
      },
    })

    tl.to(loaderText, {
      opacity: 0,
      duration: 0.5,
      ease: 'power2.out',
    }).to(loaderRef, {
      opacity: 0,
      duration: 2,
      ease: 'power2.out',
    })
  }

  return (
    <div>
      <SEO title="Home" />
      <Container>
        <LoaderContainer
          className="loaderContainer"
          id={`loader`}
          ref={div => {
            loaderRef = div
          }}
        >
          <div
            className="loader"
            id={`loaderText`}
            ref={div => {
              loaderText = div
            }}
          >
            <h1>Loading...</h1>
          </div>
        </LoaderContainer>
        <Hero hero={hero} hideLoader={hideLoader} />
        <PastWork id={`selectedWorks`} name="selectedWorks">
          <div className="pastWorkHeadline">
            <ScrollingText text={`Selected Works`} />
          </div>

          <PastWorkList
            data={data}
            previousLocation={previousLocation}
            location={location}
          />
        </PastWork>
        <Services services={services} />
        <Clients clients={clients} />
        <ContactUs />
      </Container>
    </div>
  )
}

const PastWork = styled.section`
  padding: 40px 0 13.5vw 0;

  .pastWorkHeadline {
    border-top: 1px solid white;
  }

  @media (max-width: 840px) {
    padding: 0 0 calc(7.5vh - 28px) 0;
  }
`

const LoaderContainer = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: #1c1c20;

  z-index: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    h1 {
      color: white;
      text-align: center;
      font-size: 1.5rem;
      animation: loading 4s infinite;
    }

    @keyframes loading {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

export const query = graphql`
  query postQuery {
    allContentfulPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          fullPost
          title
          id
          slug
          tags {
            slug
          }
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          hoverDescription {
            hoverDescription
          }
          hoverMedia {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
            file {
              url
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulHero {
      nodes {
        heroGallery {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp
          }
          file {
            url
          }
          title
          description
        }
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        rightDescription {
          rightDescription
          childMarkdownRemark {
            html
          }
        }
        title
      }
    }
    allContentfulTag {
      nodes {
        slug
        title
      }
    }
    allContentfulService(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          id
          date(formatString: "MMMM DD, YYYY")
          media {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
            description
            title
          }
          headline
          description {
            description
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulClients {
      edges {
        node {
          title
          clientLogos {
            id
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default Posts
