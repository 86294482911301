import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import './Clients.css'

function Clients({ clients }) {
  const clientLogos = clients[0].node.clientLogos

  const [visibleLogos, setVisibleLogos] = useState(clientLogos.slice(0, 8))
  // const [counter, setCounter] = useState(0)
  const [fadeOutId, setFadeOutId] = useState(-1)
  const [fadeInId, setFadeInId] = useState(-1)
  const [fadeInActiveId, setFadeInActiveId] = useState(-1)

  async function delay(duration) {
    return new Promise(resolve => {
      setTimeout(resolve, duration)
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {

      ;(async () => {
        setFadeInId(-1)
        setFadeInActiveId(-1)
        // this variable will receive an array with the logos that are not visible
        const invisibleLogos = clientLogos.filter(
          logo => !visibleLogos.includes(logo)
        )

        // this variable will receive a random number to make the fadeIn
        const invisibleLogoIndex = Math.floor(
          Math.random() * invisibleLogos.length
        )
        // this variable will receive a random number to make the fadeOut
        const randomLogo = invisibleLogos[invisibleLogoIndex]
        const visibleLogosRandomId = Math.floor(Math.random() * 8)

        // 1. Fade out
        setFadeOutId(visibleLogosRandomId)

        await delay(1000)

        // 2. Swapping
        visibleLogos[visibleLogosRandomId] = randomLogo

        await delay(10)

        // 3. Fade in
        setFadeInId(visibleLogosRandomId)
        setFadeOutId(-1)

        await delay(10)
        setFadeInActiveId(visibleLogosRandomId)
      })()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    // Parte 1
    <section className="ClientContainer">
      <h2 className='ClientListTitle'>Clients</h2>
      <ul className="ClientList">
        {visibleLogos.map((visibleLogo, index) => (
          <li key={visibleLogo.id}>
            <div
              className={`logoContainer ${
                fadeOutId === index ? 'list-transition-exit' : ''
              } ${fadeInId === index ? 'list-transition-enter' : ''} ${
                fadeInActiveId === index ? 'list-transition-enter-active' : ''
              }`}
            >
              <Img fluid={visibleLogo.fluid} />
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Clients
