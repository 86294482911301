import lerp from 'lerp'
import React, { useRef, useEffect } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { TextureLoader, LinearFilter } from 'three'
import { Block, useBlock } from './Blocks'
import './CustomMaterial'

const ScrollEffect = ({
  url,
  state,
  zoom,
  sections,
  hideLoader,
  showCanvas,
}) => {
  useEffect(() => {
    const canvasLoad = async () => {
      await hideLoader()

      showCanvas()
    }

    canvasLoad()
  }, [])

  const imgArr = [`https:${url}`]

  const textures = useLoader(TextureLoader, imgArr)

  const [img1] = textures.map(texture => {
    return (texture.minFilter = LinearFilter), texture
  })

  return (
    <>
      <Block
        factor={0}
        offset={0}
        state={state}
        zoom={zoom}
        sections={sections}
      >
        <Content
          left
          map={img1}
          state={state}
          zoom={zoom}
          sections={sections}
        ></Content>
      </Block>
    </>
  )
}

const Content = ({ children, map, state, zoom, sections }) => {
  const { contentMaxWidth } = useBlock(zoom, sections)

  const maxWidthAdjusted = contentMaxWidth || 0.0001

  const aspect = 1.75
  return (
    <group>
      <Plane
        scale={[maxWidthAdjusted, maxWidthAdjusted / aspect, 1]}
        color="#bfe2ca"
        map={map}
        state={state}
        zoom={zoom}
        sections={sections}
      />
      {children}
    </group>
  )
}

const Plane = ({ color = 'black', map, state, zoom, sections, ...props }) => {
  const { viewportHeight, offsetFactor } = useBlock(zoom, sections)
  const material = useRef()

  let last = state.top.current

  useFrame(() => {
    const { pages, top } = state
    material.current.scale = lerp(
      material.current.scale,
      offsetFactor - top.current / ((pages - 1) * viewportHeight),
      0.1
    )
    material.current.shift = lerp(
      material.current.shift,
      (top.current - last) / 150,
      0.1
    )
    last = top.current
  })
  return (
    <mesh {...props}>
      <planeBufferGeometry attach="geometry" args={[1, 1, 32, 32]} />
      <customMaterial
        ref={material}
        attach="material"
        color={color}
        map={map}
      />
    </mesh>
  )
}

export default ScrollEffect
