import React, { Suspense } from 'react'
import { Canvas, Dom } from 'react-three-fiber'
import State from './HeroState'
import ScrollEffect from './ScrollEffect'

const Warp = ({ image, hideLoader, showCanvas }) => {
  const zoom = 30
  const sections = 14
  const cameraZoom = 75

  return (
    <>
      <Canvas
        className=".canvas"
        orthographic
        camera={{ zoom: cameraZoom, position: [0, 0, 500] }}
      >
        <Suspense
          fallback={<Dom center className="loading" children={`Loading...`} />}
        >
          <ScrollEffect
            url={image.file.url}
            zoom={zoom}
            sections={sections}
            state={State}
            hideLoader={hideLoader}
            showCanvas={showCanvas}
          />
        </Suspense>
      </Canvas>
    </>
  )
}

export default Warp
