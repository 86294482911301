import React, { createContext, useRef, useContext, useEffect } from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import lerp from 'lerp'

const offsetContext = createContext(0)

const Block = ({
  children,
  offset,
  factor,
  state,
  zoom,
  sections,
  ...props
}) => {
  const { offset: parentOffset, sectionHeight } = useBlock(zoom, sections)
  const ref = useRef()

  offset = offset !== undefined ? offset : parentOffset

  useFrame(() => {
    const curY = ref.current.position.y
    const curTop = state.top.current
    ref.current.position.y = lerp(curY, (curTop / zoom) * factor, 0.1)
  })

  return (
    <offsetContext.Provider value={offset}>
      <group {...props} position={[0, -sectionHeight * offset * factor, 0]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  )
}

const useBlock = (zoomState, sectionsState) => {
  const sections = sectionsState
  const zoom = zoomState

  const { size, viewport } = useThree()
  const offset = useContext(offsetContext)
  const viewportWidth = viewport.width
  const viewportHeight = viewport.height
  const canvasWidth = viewportWidth / zoom
  const canvasHeight = viewportHeight / zoom
  const mobile = size.width < 1400
  const margin = canvasWidth * (mobile ? 0 : 0)
  const contentMaxWidth = canvasWidth * (mobile ? 1 : 1)
  const sectionHeight = canvasHeight
  const offsetFactor = (offset + 1.0) / sections
  return {
    viewport,
    offset,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    mobile,
    margin,
    contentMaxWidth,
    sectionHeight,
    offsetFactor,
  }
}

export { Block, useBlock }
