import React from 'react'
import styled from '@emotion/styled'
import arrow from '../assets/images/arrow6.png'
import BlockEffect from './riseEffect/BlockEffect'

const ContactUs = props => {
  const ContactContainer = styled.section`
    // padding: calc(10vh - 40px) 0 calc(20vh + 40px) 0;
    padding: 14.5vw 0 14vw 0;

    .emoji {
      font-size: 5rem;
    }

    .inquiries {
      color: white;
      font-size: 5rem;

      .inquiriesLine2 {
        display: block;
      }

      .arrowContainer {
        display: inline-block;
        width: 80px;
        margin-left: 4vw;
        transform: rotate(-45deg) translateX(-2vw);
      }

      a {
        font-weight: 800;
      }
    }

    @media (max-width: 1150px) {
      .emoji {
        font-size: 7.5vw;
      }

      .inquiries {
        font-size: 6.3vw;

        .arrowContainer {
          width: 6.5vw;
        }
      }
    }

    @media (max-width: 700px) {
      padding: 29vw 0 27vw 0;

      .inquiries {
        .arrowContainer {
          margin-left: 3vw;
        }
      }
    }
  `

  return (
    <ContactContainer id={`contactUs`} name="contactUs">
      <div className="inquiries">
        <BlockEffect
          to={150}
          content={`For all inquiries, or just`}
          y={0}
          lineHeight={1.2}
        />

        <BlockEffect
          to={150}
          content={
            <span className="inquiriesLine2">
              to say hey, reach out{' '}
              <a
                href="mailto:
              hello@frontierdigital.io"
              >
                here
              </a>
              .
              <div className="arrowContainer">
                <img src={arrow} alt="arrow" />
              </div>
            </span>
          }
          y={0}
          lineHeight={1.2}
          delay={0.1}
        />
      </div>
    </ContactContainer>
  )
}

export default ContactUs
