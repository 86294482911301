import React from 'react'
import Video from './Video.js'
import Img from 'gatsby-image'

const Media = ({ fluid, videoCheck, videoSrcURL, title, description }) => (
  <div className={`mediaContainer`}>
    {videoCheck === 'mp4' ? (
      <Video
        videoSrcURL={videoSrcURL}
        className={`${title}Video`}
        description={description}
      />
    ) : (
      <Img fluid={fluid} alt={description} className={`${title}Image`} />
    )}
  </div>
)

export default Media
