import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Card from './Card'
import { Parallax } from 'react-scroll-parallax'


const PostGroup = ({ row, previousLocation, location }) => {
  const [parallaxToggle, setParallaxToggle] = useState(true)

  const handleParallax = mediaQuery => {
    if (mediaQuery.matches) {
      setParallaxToggle(false)
    } else {
      setParallaxToggle(true)
    }
  }

  useEffect(() => {
    const width = window.matchMedia('(max-width: 700px)')
    handleParallax(width)
    width.addListener(handleParallax)

    return () => {
      width.removeListener(handleParallax)
    }
  }, [])

  return (
    <PostGroupContainer id={`postGroupContainer`}>
      {row.map((post, i) => {
        if (i === 1 || i === 3) {
          return (
            <li className={`post${i} allPosts`} key={i}>
              <Parallax y={parallaxToggle ? [125, 25] : [0, 0]}>
                <Card
                  key={post.node.id}
                  {...post.node}
                  previousLocation={previousLocation}
                  location={location}
                />
              </Parallax>
            </li>
          )
        } else {
          return (
            <li className={`post${i} allPosts`} key={i}>
              <Card
                key={post.node.id}
                {...post.node}
                previousLocation={previousLocation}
                location={location}
              />
            </li>
          )
        }
      })}
    </PostGroupContainer>
  )
}

export default PostGroup

const PostGroupContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;

  li {
    width: calc(100% / 3 - 2%);
    margin: 0 0 6vw 0;

    transition: all 0.6s;
  }

  li.post0 {
    .gatsby-image-wrapper {
      div:first-of-type {
        padding-bottom: 63% !important;
        height: 0;
      }
    }
  }

  li:first-child {
    width: calc(100% * 2 / 3 - 1%);

    .postContainer {
      position: relative;
    }
  }

  li:nth-child(2) {
    margin-right: 3%;
    position: relative;
    z-index: 10;

    .postContainer {
      position: relative;
    }
  }

  li:nth-child(3) {
    order: 4;

    margin-right: 1.5%;
  }

  li:nth-child(4) {
    order: 3;

    margin-left: 1.5%;
    position: relative;
    z-index: 10;
  }

  &:nth-child(2n) {
    flex-direction: row;

    li:nth-child(2) {
      margin-right: 0;
      margin-left: 3%;
      z-index: 10;
    }

    li:nth-child(3) {
      order: 4;

      margin-right: 0;
      margin-left: 1.5%;
    }

    li:nth-child(4) {
      order: 3;

      margin-left: 0;
      margin-right: 1.5%;
      z-index: 10;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column !important;
    align-items: flex-start !important;

    li,
    li:first-child,
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4) {
      width: 100% !important;
      margin: 0 !important;
      padding: 28px 0;
    }
  }
`
