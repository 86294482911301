import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import PostGroup from './PostGroup'
import gsap from 'gsap'
import LinkFlip from '../LinkFlip'
import { withController } from 'react-scroll-parallax'

const PastWorkList = ({ data, parallaxController, previousLocation, location }) => {
  const [allPostsNested, setAllPostsNested] = useState(null)
  const [allPostsList, setAllPostsList] = useState(null)
  const [selectedPostsNested, setSelectedPostsNested] = useState(null)
  const [currentTag, setCurrentTag] = useState(null)

  const posts = data.allContentfulPost.edges
  const tags = data.allContentfulTag.nodes

  // every four elements, create a new array row

  let groupedPosts = new Array(Math.ceil(posts.length / 4))

  for (let i = 0; i < groupedPosts.length; i++) {
    groupedPosts[i] = new Array(4)
  }

  let rowCounter = 0
  let colCounter = 0

  for (let j = 0; j < posts.length; j++) {
    if (colCounter < 4) {
      groupedPosts[rowCounter][colCounter] = posts[j]
      colCounter++
    } else {
      // add row
      rowCounter++

      // reset column
      colCounter = 0

      // continue to add posts
      groupedPosts[rowCounter][colCounter] = posts[j]
      colCounter++
    }
  }

  useEffect(() => {
    setAllPostsList(posts)
    setAllPostsNested(groupedPosts)
    setCurrentTag('all')
    parallaxController.update()
  }, [])

  const handleTag = tag => {
    if (tag === 'all') {
      setSelectedPostsNested(allPostsNested)
    } else {
      let selection = []

      // search allPosts for list of elements

      allPostsList.forEach(ele => {
        ele.node.tags.forEach(tagArr => {
          if (tagArr.slug === tag) {
            selection.push(ele)
          }
        })
      })

      // return ones that match, save to setPostSelection, then map

      let selectedPosts = new Array(Math.ceil(selection.length / 4))

      for (let i = 0; i < selectedPosts.length; i++) {
        selectedPosts[i] = new Array(4)
      }

      let rowCounter = 0
      let colCounter = 0

      for (let j = 0; j < selection.length; j++) {
        if (colCounter < 4) {
          selectedPosts[rowCounter][colCounter] = selection[j]
          colCounter++
        } else {
          // add row
          rowCounter++

          // reset column
          colCounter = 0

          // continue to add posts
          selectedPosts[rowCounter][colCounter] = selection[j]
          colCounter++
        }
      }

      // set state to selectedPosts
      setSelectedPostsNested(selectedPosts)
    }
  }

  let allPosts = useRef(null)

  const pushDownAll = () => {

    const pushDown = gsap.timeline({onComplete : () => {
      parallaxController.update()
    }})

    pushDown
      .to(allPosts, 0.6, {
        opacity: 0,
        y: '200px',
      })
      .to(allPosts, 0.6, { delay: 0.2, opacity: 1, y: 0 })
  }

  return (
    <PastWorkContainer>
      <WorkTags>
        <li
          id={`all`}
          className={`tagTitle`}
          onClick={() => {
            if (currentTag !== 'all') {
              pushDownAll()

              setTimeout(function() {
                handleTag('all')
                setCurrentTag('all')
              }, 600)
            }
          }}
        >
          <button type="button">
            <LinkFlip word={`All`} />
          </button>
        </li>
        {tags.map(tagName => {
          return (
            <li
              id={tagName.slug}
              key={tagName.slug}
              className={`tagTitle`}
              onClick={() => {
                if (currentTag !== tagName.slug) {
                  pushDownAll(tagName.slug)

                  setTimeout(function() {
                    handleTag(tagName.slug)
                    setCurrentTag(tagName.slug)
                  }, 600)
                }
              }}
            >
              <button type="button">
                <LinkFlip word={tagName.title} />
              </button>
            </li>
          )
        })}
      </WorkTags>

      <PostSection ref={div => {allPosts = div}}>
        {selectedPostsNested
          ? selectedPostsNested.map((row, key) => {
              return <PostGroup row={row} key={key} previousLocation={previousLocation} location={location}/>
            })
          : groupedPosts.map((row, key) => {
              return (
                <PostGroup
                  row={row}
                  key={key}
                  previousLocation={previousLocation}
                  location={location}
                />
              )
            })}
      </PostSection>
    </PastWorkContainer>
  )
}

export default withController(PastWorkList)

const PastWorkContainer = styled.div``

const WorkTags = styled.ul`
  margin: 0 0 30px 0;
  padding: 10px 0 10px 0;

  display: flex;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  li {
    margin-right: 6%;
    font-weight: 100;
    transition: all 0.1s;
    display: flex;
    align-items: flex-end;
  }

  button {
    transform: translateY(2px);
    color: white;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  @media (max-width: 840px) {
    li {
      font-size: 0.9rem;
      margin-right: 50px;
    }
  }

  @media (max-width: 550px) {
    justify-content: space-between;

    li {
      font-size: 0.9rem;
      margin-right: 0;
    }
  }
`

const PostSection = styled.div`
  padding: 5vw 0 0 0;
`
