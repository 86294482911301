import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import Media from '../Media'
import gsap from 'gsap'

const Card = ({
  fullPost,
  slug,
  heroImage,
  title,
  publishDate,
  body,
  tags,
  hoverDescription,
  hoverMedia,
  previousLocation,
  location,
  ...props
}) => {
  let cardRef = useRef(null)
  let textRef = useRef(null)

  const [refView, inView] = useInView({
    rootMargin: '0px 0px',
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      gsap.to(cardRef, 0.6, {
        pointerEvents: 'auto',
        opacity: 1,
        y: 0,
        delay: 0.2,
      })
    }
  }, [inView])

  const triggerTransition = (timeline, target) => {
    timeline
      .to(target, {
        transform: `translateY(0vh)`,
        display: 'flex',
        duration: 0,
      })
      .to(target, {
        delay: '0.2',
        transform: 'translateY(-100vh)',
        duration: 0.5,
        display: 'flex',
      })
  }

  const handleExpand = ref => {
    ref.style.maxHeight = ref.scrollHeight + 20 + 'px'
    ref.style.paddingBottom = `20px`
  }

  const handleContract = ref => {
    ref.style.maxHeight = null
    ref.style.paddingBottom = `0`
  }

  return (
    <>
      {heroImage && body && (
        <PostContainer ref={refView} className={`postContainer`}>
          <Post
            ref={div => (cardRef = div)}
            featured={props.featured}
            className={`post`}
          >
            {fullPost ? (
              <a
                href={`/${slug}/`}
                onClick={event => {
                  event.preventDefault()
                  previousLocation.current = location.pathname;

                  const swiper = document.getElementById('swiperMain')

                  var tl = gsap.timeline({
                    onComplete: () => navigate(`/${slug}/`),
                  })

                  triggerTransition(tl, swiper)
                }}
                onMouseEnter={
                  hoverDescription ? () => handleExpand(textRef) : null
                }
                onMouseLeave={
                  hoverDescription ? () => handleContract(textRef) : null
                }
              >
                <div className={`cardMediaContainer`}>
                  <Img fluid={heroImage.fluid} className={`mainImage`} />

                  {hoverMedia ? (
                    <Media
                      videoCheck={hoverMedia.file.url.slice(-3)}
                      videoSrcURL={hoverMedia.file.url}
                      fluid={hoverMedia.fluid}
                      alt={hoverMedia.title}
                      title={hoverMedia.title}
                      description={hoverMedia.description}
                    />
                  ) : null}
                </div>

                <div className={`textContainer`}>
                  <div className="upperTextContainer">
                    <h4>{title}</h4>
                    <p>{publishDate.substr(publishDate.length - 4)}</p>
                  </div>
                  {hoverDescription ? (
                    <div
                      className={`lowerTextContainer`}
                      id={`${slug}Text`}
                      ref={div => {
                        textRef = div
                      }}
                    >
                      <p>{hoverDescription.hoverDescription}</p>
                    </div>
                  ) : null}
                </div>
              </a>
            ) : (
              <div
                href={`/${slug}/`}
                onMouseEnter={
                  hoverDescription ? () => handleExpand(textRef) : null
                }
                onMouseLeave={
                  hoverDescription ? () => handleContract(textRef) : null
                }
              >
                <div className={`cardMediaContainer`}>
                  <Img fluid={heroImage.fluid} className={`mainImage`} />

                  {hoverMedia ? (
                    <Media
                      videoCheck={hoverMedia.file.url.slice(-3)}
                      videoSrcURL={hoverMedia.file.url}
                      fluid={hoverMedia.fluid}
                      alt={hoverMedia.title}
                      title={hoverMedia.title}
                      description={hoverMedia.description}
                    />
                  ) : null}
                </div>

                <div className={`textContainer`}>
                  <div className="upperTextContainer">
                    <h4>{title}</h4>
                    <p>{publishDate.substr(publishDate.length - 4)}</p>
                  </div>
                  {hoverDescription ? (
                    <div
                      className={`lowerTextContainer`}
                      id={`${slug}Text`}
                      ref={div => (textRef = div)}
                    >
                      <p>{hoverDescription.hoverDescription}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </Post>
        </PostContainer>
      )}
    </>
  )
}

export default Card

const PostContainer = styled.div`
  pointer-events: none;
`

const Post = styled.div`

  width: 100%;
  position: relative;
  border-radius: 2px;
  opacity: 0;
  transform: translateY(200px);

  h4 {
    font-size: 1rem;
    color: white;
  }

  .cardMediaContainer {
    position: relative;
  }

  .mediaContainer {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: 0.6s linear;

    .videoContainer {
      height: 100%;
    }

    video {
        width: auto;
        height: 100%;
        object-fit: cover;

      }
  }

  &:hover .mediaContainer {
    opacity: 1;
  }

  .textContainer {
            border-bottom: 1px solid white;

  }

  .upperTextContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;


    h4 {
      font-weight: 500;
      font-size: 1rem;
      font-family: 'SuisseIntl', sans-serif;

    }

    p {
      font-weight: 100;
      font-size: 1rem;
    }
  }

  .lowerTextContainer {
    padding: 0;

    max-height: 0;
        overflow-y: hidden;

        padding-top: 0px;

        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;

    p {
      font-size: 0.9rem;
      line-height: 1.4;
    }
  }

  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    text-decoration: none;

  
    }

  }

  .gatsby-image-wrapper {

      div:first-of-type {
           padding-bottom: 120% !important;
           height: 0;

      }
    }


    @media (max-width: 700px) {
       .gatsby-image-wrapper {

      div:first-of-type {
           padding-bottom: 63% !important;
           height: 0;

      }
    }


    }

`
