import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'

const ScrollingText = ({ text }) => {
  let headline1 = useRef(null)
  let headline2 = useRef(null)

  useEffect(() => {
    const textTl1 = gsap.timeline({ repeat: -1 })
    const textTl2 = gsap.timeline({ repeat: -1 })

    textTl1.to(headline1, 60, {
      x: '-100%',
      ease: 'none',
    })
    textTl2.to(headline2, 60, {
      x: '-100%',
      ease: 'none',
    })
  }, [])

  const TitleContainer = styled.div`
    padding: 30px 0 30px 0;
    white-space: nowrap;
    display: flex;
    transform: translateY(0.5vw);

    h2 {
      font-size: 8vw;
      // animation: scroll 60s linear infinite;

      span {
        display: block;
      }
    }

    @media (max-width: 840px) {
      h2 {
        font-size: 4rem;
      }
    }

    @media (max-width: 550px) {
      h2 {
        font-size: 3rem;
      }
    }

    // @keyframes scroll {
    //   from {
    //     transform: translateX(0%);
    //   }
    //   to {
    //     transform: translate(-100%);
    //   }
    // }

    @media (min-width: 1800px) {
      h2 {
        font-size: 8.5rem;
      }
    }
  `

  return (
    <TitleContainer>
      <h2
        ref={div => {
          headline1 = div
        }}
      >
        {text} — {text} — {text} — {text} —&nbsp;
      </h2>
      <h2
        ref={div => {
          headline2 = div
        }}
      >
        {text} — {text} — {text} — {text} —&nbsp;
      </h2>
    </TitleContainer>
  )
}

export default ScrollingText
