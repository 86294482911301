import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import gsap from 'gsap'
import TextEffect from '../components/riseEffect/TextEffect'
import arrow from '../assets/images/arrow6.png'
import { scroller } from 'react-scroll'

const HeroTitle = ({ description }) => {
  let arrowRef = useRef(null)
  let childContainer = []
  let parentContainer = []

  const title1 = 'Frontier'.split('')
  const title2 = 'Digital'.split('')

  for (let i = 0; i < 16; i++) {
    childContainer.push(useRef(null))
    parentContainer.push(useRef(null))
  }

  useEffect(() => {
    let timeline = gsap.timeline()

    timeline.to(arrowRef, 1, {
      duration: 1,
      delay: 3.3,
      transform: `rotate(90deg) translate(15px, 0)`,
    })

    timeline.to(arrowRef, 1, {
      duration: 2,
      transform: `rotate(90deg) translate(0px, 0)`,
      repeat: -1,
      yoyo: true,
    })
  }, [])

  const handleHover = ref => {
    const letterTl = gsap.timeline()

    letterTl
      .set(ref, { opacity: 0 })
      .to(ref, { opacity: 0, duration: 0.4 })
      .to(ref, { opacity: 1, duration: 1.3 })
  }

  const getRandom = (min, max) => {
    return Math.random() * (max - min) + min
  }

  useEffect(() => {
    setTimeout(() => {
      for (let j = 0; j < parentContainer.length; j++) {
        if (j !== 8) {
          const tl = gsap.timeline()

          tl.set(parentContainer[j], {
            opacity: 0,
            y: `${getRandom(160, 300)}px`,
          }).to(parentContainer[j], {
            opacity: 1,
            y: 0,
            duration: getRandom(1.1, 1.3),
            delay: getRandom(1.1, 1.4),
            ease: 'power2.out',
          })
        }
      }
    }, 400)
  })

  return (
    <TitleContainer>
      <HeroHeadline>
        {title1.map((letter, key) => {
          return (
            <div
              className={`char char${key}`}
              key={key}
              ref={div => {
                parentContainer[key] = div
              }}
            >
              <div
                ref={div => {
                  childContainer[key] = div
                }}
                onMouseEnter={() => handleHover(childContainer[key])}
              >
                {letter}
              </div>
            </div>
          )
        })}

        <span>
          <div
            className="char char9"
            ref={div => {
              childContainer[8] = div
            }}
          >
            &nbsp;
          </div>

          {title2.map((letter, key) => {
            return (
              <div
                className={`char char${key + 9}`}
                key={key + 9}
                ref={div => {
                  parentContainer[key + 9] = div
                }}
              >
                <div
                  ref={div => {
                    childContainer[key + 9] = div
                  }}
                  onMouseEnter={() => handleHover(childContainer[key + 9])}
                >
                  {letter}
                </div>
              </div>
            )
          })}
        </span>
      </HeroHeadline>

      <div className="description">
        <TextEffect
          lineHeight={1.4}
          text={description}
          style={`lines`}
          delay={1.9}
        />
        <a
          href="#selectedWorks"
          onClick={() => {
            event.preventDefault()

            scroller.scrollTo('selectedWorks', {
              delay: 100,
              smooth: true,
            })
          }}
        >
          <div className="arrowContainer">
            <img
              src={arrow}
              alt="arrow"
              id={`heroArrow`}
              ref={img => {
                arrowRef = img
              }}
            />
          </div>
        </a>
      </div>
    </TitleContainer>
  )
}

export default HeroTitle

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  pointer-events: none;

  .description {
    width: 17vw;
    font-size: 1.2vw;
    font-weight: 100;
    text-transform: uppercase;
    color: white;
    margin-top: 15px;
    align-self: flex-end;

    transform: translateX(0.2vw);
  }

  .arrowContainer {
    pointer-events: auto;
    margin-top: 20px;
    width: 40px;
    height: 100px;

    overflow-y: hidden;

    img {
      transform: rotate(90deg) translate(-60px, 0);
    }
  }

  @media (max-width: 1350px) {
    .description {
      font-size: 1.1rem;
      width: 240px;
    }
  }

  @media (max-width: 450px) {
    .description {
      font-size: 0.9rem;
      width: 195px;
    }
  }
`

const HeroHeadline = styled.h1`
  position: relative;

  .char {
    position: relative;
    pointer-events: auto;
  }

  @media (max-width: 450px) {
    span {
      display: none;
    }
  }
`
